import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
    return (
        <div className="pp-footer mb-5">
            <div className="container-fluid">
                <div className="d-flex justify-content-between pp-footer-content">
                    <div> Copyright © 2022. All Rights Reserved by OCULLO</div>
                    <div className="text-end pp-footer-link">
                        {/* <Link target="_blank" rel="noreferrer" to='/privacy-policy' >Privacy Policy</Link>
                        <Link target="_blank" rel="noreferrer"  to='/terms-conditions'>Terms of Use</Link>
                        <Link target="_blank" rel="noreferrer"  to="/cookies-policy">Cookies Policy</Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
