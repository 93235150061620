export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}

// mainnet
export const multicallAddress = "0xFa0eaF00A63C95a53DeDb6719D0DD04cB1Cc3C31";
export const tokenStakingAddress = "0x43Bf550A97fDb4F2b60192BDdAD600eeC4124a0b"; 
export const tokenAddress = "0x4b3395D7518c6aCA3f0970cB70e0Ec977fc138D4";


// bsc Testnet

// export const multicallAddress = "0x18359D62ecDA61b8D5d8db374ba4A3821091ce12";
// export const tokenStakingAddress = "0x2Af24BBD8e7162ee275839cF402D9822Af21DDfe"; 
// export const tokenAddress = "0x496EEe3872cF3204a06614975e4cf13cF03Db593";

// Sapoliya

// export const multicallAddress = "0x36565B3a437411917e83759BaD9D99B055283482";
// export const tokenStakingAddress = "0xF02D22AE674AB614D4131beA8682187058F9F9Ee"; 
// export const tokenAddress = "0x95D59d33E017533b996eAf351cf7428fE7510bc0";
