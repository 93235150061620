import { useEffect, useState } from "react";
import { useMulticallContract } from "./useContracts";
import { stakeItf, tokenItf, multicallItf } from "../json/interface";
import {
  tokenStakingAddress,
  tokenAddress,
  multicallAddress,
} from "./constant";
import { CHAIN_ID, web3 } from "./connectors";
import { useWeb3React } from "@web3-react/core";

const calls = [
  [tokenStakingAddress, stakeItf.encodeFunctionData("totalStakedSoFar")],
  [tokenStakingAddress, stakeItf.encodeFunctionData("maxStakeAmount")],
  [tokenStakingAddress, stakeItf.encodeFunctionData("minStakeAmount")],
  [tokenStakingAddress, stakeItf.encodeFunctionData("generationTime")],
  [tokenStakingAddress, stakeItf.encodeFunctionData("totalStakers")],
];

export const useCommonStats = (update) => {
  const [stats, setStats] = useState({
    totalStaked: 0,
    totalStakers: 0,
    maxStakeAmount: 0,
    minStakeAmount: 0,
    generationTime: 0,
  });

  const mc = useMulticallContract();

  useEffect(() => {
    const fetch = async () => {
      const [, data] = await mc.aggregate(calls);

      setStats({
        totalStaked:
          stakeItf
            .decodeFunctionResult("totalStakedSoFar", data[0])[0]
            .toString() / Math.pow(10, 18),
        maxStakeAmount:
          stakeItf
            .decodeFunctionResult("maxStakeAmount", data[1])[0]
            .toString() / Math.pow(10, 18),
        minStakeAmount:
          stakeItf
            .decodeFunctionResult("minStakeAmount", data[2])[0]
            .toString() / Math.pow(10, 18),
        generationTime: stakeItf
          .decodeFunctionResult("generationTime", data[3])[0]
          .toString(),
        totalStakers: stakeItf
          .decodeFunctionResult("generationTime", data[4])[0]
          .toString(),
      });
    };

    if (mc) {
      try {
        fetch();
        console.log("fetching");
        console.log(stats);
      } catch (err) {
        console.log(err);
      }
    } else {
      setStats({
        totalStaked: 0,
        totalStakers: 0,
        maxStakeAmount: 0,
        minStakeAmount: 0,
        generationTime: 0,
      });
    }
  }, [mc, update]);

  return stats;
};

export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const [stats, setStats] = useState({
    balance: 0,
    bnb_balance: 0,
    isApprove: 0,
    userstaked: 0,
    stakeStart: 0,
    seeReward: 0,
    totalunstaked: 0,
    userTotalDeduction: 0,
    userTotalRecievable: 0,
    unstakeable: 0,
  });

  const mc = useMulticallContract();

  useEffect(() => {
    const fetch = async () => {
      console.log("fetching");
      console.log(account);
      const calls = [
        [tokenAddress, tokenItf.encodeFunctionData("balanceOf", [account])],
        [
          multicallAddress,
          multicallItf.encodeFunctionData("getEthBalance", [account]),
        ],
        [
          tokenAddress,
          tokenItf.encodeFunctionData("allowance", [
            account,
            tokenStakingAddress,
          ]),
        ],
        [
          tokenStakingAddress,
          stakeItf.encodeFunctionData("userStakedAmount", [account]),
        ],
        [
          tokenStakingAddress,
          stakeItf.encodeFunctionData("seeReward", [account]),
        ],
        [
          tokenStakingAddress,
          stakeItf.encodeFunctionData("userUnstakedAmount", [account]),
        ],
        // [
        //   tokenStakingAddress,
        //   stakeItf.encodeFunctionData("userTotalDeduction", [account]),
        // ],
        [
          tokenStakingAddress,
          stakeItf.encodeFunctionData("userTotalRecievable", [account]),
        ],
        [
          tokenStakingAddress,
          stakeItf.encodeFunctionData("unstakeable", [account]),
        ],
      ];
      console.log("calls");

      const [, data] = await mc.aggregate(calls);

      console.log("data");
      console.log(
        web3.utils.fromWei(
          multicallItf
            .decodeFunctionResult("getEthBalance", data[1])[0]
            .toString(),
          "ether"
        )
      );
      setStats({
        balance: web3.utils.fromWei(
          tokenItf.decodeFunctionResult("balanceOf", data[0])[0].toString(),
          "ether"
        ),
        bnb_balance: web3.utils.fromWei(
          multicallItf
            .decodeFunctionResult("getEthBalance", data[1])[0]
            .toString(),
          "ether"
        ),
        isApprove: web3.utils.fromWei(
          tokenItf.decodeFunctionResult("allowance", data[2])[0].toString(),
          "ether"
        ),
        userstaked: web3.utils.fromWei(
          stakeItf
            .decodeFunctionResult("userStakedAmount", data[3])[0]
            .toString(),
          "ether"
        ),
        seeReward: web3.utils.fromWei(
          stakeItf.decodeFunctionResult("seeReward", data[4])[0].toString(),
          "ether"
        ),
        totalunstaked: web3.utils.fromWei(
          stakeItf
            .decodeFunctionResult("userUnstakedAmount", data[5])[0]
            .toString(),
          "ether"
        ),
        // userTotalDeduction: web3.utils.fromWei(
        //   stakeItf
        //     .decodeFunctionResult("userTotalDeduction", data[6])[0]
        //     .toString(),
        //   "ether"
        // ),
        userTotalRecievable: web3.utils.fromWei(
          stakeItf
            .decodeFunctionResult("userTotalRecievable", data[6])[0]
            .toString(),
          "ether"
        ),
        unstakeable: web3.utils.fromWei(
          stakeItf.decodeFunctionResult("unstakeable", data[7])[0].toString(),
          "ether"
        ),
      });
    };

    if (mc && account && chainId === CHAIN_ID) {
      try {
        fetch();
        console.log("fetching");
        console.log(stats);
      } catch (err) {
        console.log(err);
      }
    } else {
      setStats({
        balance: 0,
        bnb_balance: 0,
        isApprove: false,
        userstaked: 0,
        seeReward: 0,
        stakeStart: 0,
        totalunstaked: 0,
        userTotalDeduction: 0,
        userTotalRecievable: 0,
        unstakeable: 0,
      });
    }
  }, [mc, account, updater, chainId]);

  return stats;
};
